.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; 
    padding: 10px;
}

.title {
    font-size: 2.5rem; 
    font-weight: bold; 
    color: #3f51b5; 
}